import React from 'react';

const PurchaseLink = () => (
  <iframe 
    style={{width:'120px', height: '240px'}} 
    title="Amazon Link to purchase The Advancing Guitarist"
    marginWidth="0" 
    marginHeight="0" 
    scrolling="no" 
    frameBorder="0" 
    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=wholestep-20&marketplace=amazon&amp;region=US&placement=0881885894&asins=0881885894&linkId=46f3cd9b0ef92528ca5c3afaf8b4af56&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0d3e6b&bg_color=ffffff">
  </iframe>
);

export default PurchaseLink;
