import React from 'react'

import PurchaseLink from './PurchaseLink'
import './PurchaseCopy.css'

const PurchaseCopy = ({ children, showLink }) => (
  <div className="copy-block">
    <p>
      {children}
    </p>
    { showLink && <PurchaseLink />}
  </div>
);

export default PurchaseCopy;
