import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PurchaseCopy from "../components/PurchaseCopy"

const theRules = [
  "Play up and down each string individually. Do NOT switch strings until the next cycle is indicated.",
  "Play each mode/string combination for two to four minutes (HINT: 4 minutes is more relaxing)",
  "Only play the natural notes in the key of C (eg. the white keys on a piano)",
  "Try playing left-hand movement with one finger (eg. index, pinkie) at a time.",
  "You can bend the string, but only a half-step: B -> C, C -> B, E -> F or F -> E",
  "Don't forget that the open string (EADGBE) is accessible, no matter where your left-hand is currently resting.",
  "Try playing left-hand movement with two fingers at a time, and then three fingers at a time.",
  "Getting bored after a bit? Have you tried using vibrato? What about harmonics?",
  "Try changing the attack of your pick (or fingers).",
];

const HowItWorksPage = () => (
  <Layout>
    <SEO title="How It Works" />
    { false && "TODO: extract this text-page class"}
    <div className="text-page">
      <h2>
        Overview
      </h2>
      <PurchaseCopy>
        This website was inspired by the book <b>The Advancing Guitarist</b> by jazz guitarist Mick Goodrick. It aims to serve as an aid for learning modal improvisation, specifically playing scales up and down a single guitar string. Read more about the book and its author <Link to='/the-advancing-guitarist'>here</Link>. (<em>FYI:</em> If you purchase the book using the affiliate link to the right, it helps me continue to improve this site and add new features/content!)
      </PurchaseCopy>
      <h2>How to use this site</h2>
      <p>
        This simple application is meant to guide you through the foundational exercise suggested in <Link to="/the-advancing-guitarist">the book</Link>, <b>The Advancing Guitarist</b>.  The value proposition of this exercise is that we as guitarists mostly learn "position" playing (eg. "CAGED" method, and others), whereas the book hypothesizes that the first stringed instrument may have been a single-string "unitar" and perhaps we should seek to understand how the first <em>unitarists</em> played their instruments to inform our own modern guitar playing! 
      </p>
      <p>
        By default, you will be presented with a new (random) mode to practice 
        up and down a single guitar string. For example, you might first be 
        instructed to play the D Dorian up and down the B string for four minutes. 
        Remember that D is the tonal center of D dorian, and the left-hand side 
        graphic will display the scale as played on a single string (starting 
        with D). You can change the cycle time to two minutes in the control panel 
        if you like, at which point the period shrinks to two minutes, and the 
        timer restarts. Honestly, I think you'll need the four minutes to really 
        "study" the mode. After the timer runs out, you will be given a new 
        (hopefully random) mode and string combination to practice.
      </p>
      <p>
        You can also enable an audio bell by checking "Sound", to alert you when:
        <ul>
          <li>There's 30 seconds left in the cycle</li>
          <li>The cycle is complete</li>
        </ul>
      </p>
      <p>
        If you don't like (or get bored with) the current mode, just click the "OVERRIDE!" button, and you'll be given a new mode/string combination.
      </p>
      <p>
        You can pause and resume by pressing the button to the right of the timer.
      </p>
      <p>
        <h2>
          "The Rules"
        </h2>
        <p>
          This is a small subset of "rules" mandated by Goodrick in the book. It's worth following them in order to get a feel for playing a scale/mode in it's "purest" form. The whole point is to constrain your options so that you are forced to concentrate on intervals and dynamics. You don't HAVE to follow the rules...no one's going to throw you in jail. Don't be SO dramatic. 
        </p>
        <ul>
          {theRules.map(rule => <li>{rule}</li>)}
          <li>
            <b><i>...need MORE rules???</i></b>
          </li>
        </ul>
      </p>
      <PurchaseCopy>
        <i>
          Click on the link to the right to buy the book for more tips! It 
          provides an additional wealth of information. I will probably 
          return to this book every year for the rest of my guitar-playing life.
        </i>
      </PurchaseCopy>

      <p>
        <Link to="/">Back to home</Link>
      </p>
    </div>
  </Layout>
)

export default HowItWorksPage
